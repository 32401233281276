/**
 * After wpm is loaded
 * we first check if wpmDataLayer is loaded,
 * and as soon as it is, we load the pixels,
 * and as soon as the page load is complete,
 * we fire the wpmLoad event.
 *
 * @param {{pro:bool}} wpmDataLayer.version
 *
 * https://stackoverflow.com/a/25868457/4688612
 * https://stackoverflow.com/a/44093516/4688612
 */
wpm.wpmDataLayerExists()
	.then(function () {
		console.log("WooCommerce Pixel Manager: " + (wpmDataLayer.version.pro ? "Pro" : "Free") +" Version " + wpmDataLayer.version.number + " loaded")
		jQuery(document).trigger("wpmPreLoadPixels", {})
	})
	.then(function () {
		wpm.pageLoaded().then(function () {
			jQuery(document).trigger("wpmLoad", {})
		})
	})

