(function (wpm, $, undefined) {

	wpm.load_hotjar_pixel = function () {

		try {
			wpmDataLayer.pixels.hotjar.loaded = true;

			// @formatter:off
			(function(h,o,t,j,a,r){
				h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
				h._hjSettings={hjid:wpmDataLayer.pixels.hotjar.site_id,hjsv:6};
				a=o.getElementsByTagName('head')[0];
				r=o.createElement('script');r.async=1;
				r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
				a.appendChild(r);
			})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
			// @formatter:on

		} catch (e) {
			console.error(e)
		}
	}

}(window.wpm = window.wpm || {}, jQuery));


(function () {

	jQuery(document).on("wpmLoadPixels", function () {

		if (wpmDataLayer?.pixels?.hotjar?.site_id && !wpmDataLayer?.pixels?.hotjar?.loaded) {
			if (wpm.canIFire("analytics", "hotjar") && !wpmDataLayer?.pixels?.hotjar?.loaded) wpm.load_hotjar_pixel()
		}
	})

})()



